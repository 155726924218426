/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useGetBusinessEntitiesQuery } from 'src/common/apis'
import AutocompleteWithCreate from 'src/common/components/autocomplete/common/autocomplete-with-create'
import { Option } from '../grid/types'

interface CustomerEntitiesAutocompleteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any
  readOnly?: boolean
  multiple?: boolean
  name?: string
  customerId?: string
  label?: string
  size?: 'small' | 'medium'
  onChange?: any
}

const BusinessEntitiesAutocomplete = ({
  formik,
  readOnly = false,
  name = 'businessEntityId',
  customerId,
  size = 'medium',
  label = 'Entity',
  onChange,
  multiple = false,
}: CustomerEntitiesAutocompleteProps) => {
  const entitiesQuery = useGetBusinessEntitiesQuery(customerId || '')

  // useEffect(() => {
  //   if (!entitiesQuery.isLoading && entitiesQuery.data?.length > 0) {
  //     // Check if entitiesQuery has finished loading and has data
  //     const firstOption = entitiesQuery.data[0]
  //     // Set the first option's value to formik
  //     console.log(value === firstOption.value, value, firstOption.value)
  //     if (value === firstOption.value) {
  //       return
  //     }
  //     formik.setFieldValue(name, firstOption.value || firstOption.id)

  //     onChange && onChange(firstOption)
  //   }
  // }, [entitiesQuery.data, entitiesQuery.isLoading, customerId])

  return (
    <AutocompleteWithCreate
      formik={formik}
      readOnly={readOnly}
      options={(entitiesQuery.data as Option[]) || []}
      name={name}
      label={label}
      isLoading={!!customerId && entitiesQuery.isLoading}
      allowCreate={false}
      size={size}
      multiple={multiple}
      onChange={(id: string) =>
        onChange && onChange(entitiesQuery?.data?.find((e: any) => e.id === id))
      }
    />
  )
}

export default BusinessEntitiesAutocomplete
