/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldArray, Form, FormikProvider } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import {
  alpha,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import _ from 'lodash'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { makeStyles } from '@mui/styles'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import AutocompleteWithCreate from 'src/common/components/autocomplete/common/autocomplete-with-create'
import MoveDownIcon from '@mui/icons-material/MoveDown'
import RenderField from 'src/common/components/form/RenderField'
import { useGetInvoiceRequests } from 'src/common/apis'
import { useCallback } from 'react'
import { Option } from 'src/common/components/grid/types'
import { CurrenciesOptions } from 'src/modules/quotes/form/formConfigs'
import { MOTOptions, UOMOptions } from '../../files/shipments/formConfigs'
import CustomerAutocomplete from 'src/common/components/autocomplete/customer'
import BusinessEntitiesAutocomplete from 'src/common/components/autocomplete/customer-entities-static'
import { addDays } from 'date-fns'
import {
  calculateFinalTotal,
  calculateRowTotal,
  calculateSubtotal,
} from './utils'
import { OperationsFilesForm as FormProps } from 'src/common/types/Operation'
import RenderReadOnlyNumberField from 'src/common/components/form/ReadOnlyNumberInput'
import { useGetCurrencySymbol } from 'src/common/hooks/use-currency-sign'
import StaticContactsAutocomplete from 'src/common/components/autocomplete/contacts-static'

const useStyles = makeStyles((theme: any) => ({
  itemGrid: {
    '&:hover, &:focus-within': {
      backgroundColor: alpha(theme.palette.background.default, 0.2),
    },
  },
}))
// const officeData = JSON.parse(localStorage.getItem('activeOffice') || '{}')

const InvoiceForm = ({
  formik,
  readOnly,
  reOpenedFields,
  isLoading,
  showExchangeRate = false,
}: FormProps) => {
  const classes = useStyles()

  const quotesRelatedData = useGetInvoiceRequests()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optionMap = (item: any) => ({
    value: item.id,
    label: item.name,
    ...item,
  })

  const officeData = JSON.parse(localStorage.getItem('activeOffice') || '{}')

  const taxesOptions =
    officeData?.taxRates?.map((item: any) => ({
      ...item,
      rate: item.value || 0,
      value: item.id || '',
    })) || []

  const [services] = quotesRelatedData

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const servicesOptions = services.data || []

  const taxtPercentage =
    Number(
      taxesOptions.find((x: any) => x.id === formik.values.taxRate)?.rate,
    ) || 0

  const serviceTypeItemOptions = useCallback(
    (item: any) => {
      let opts

      if (item.serviceType) {
        const foundOption = _.find(servicesOptions, { value: item.serviceType })
        opts = _.chain(foundOption?.items || [])
          .uniqBy('id')
          .map(optionMap)
          .value()
      } else {
        opts = _.chain(servicesOptions)
          .flatMap((option) =>
            option.items.map((subItem: any) => ({
              parentId: option.value,
              ...subItem,
            })),
          )
          .uniqBy('id')
          .map(optionMap)
          .value()
      }

      return opts
    },
    [servicesOptions],
  )

  const findParentItem = (elementValue: any) => {
    const parent = servicesOptions.find((option: any) =>
      option.items.some((subItem: any) => subItem.id === elementValue),
    )
    return parent
  }
  const onDragEnd = (result: any) => {
    if (!result.destination) return

    const items = Array.from(formik.values.lineItems)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    formik.setFieldValue('lineItems', items)

    formik.setFieldTouched('lineItems', true, true)
  }

  const isFiledReadOnly = (fieldName: string) => {
    return reOpenedFields?.includes(fieldName) ? false : readOnly
  }

  const currencySymbol = useGetCurrencySymbol(formik.values.currency)

  const theme = useTheme()

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={3}
          mt={2}
          mb={3}
          display={'flex'}
          alignContent={'start'}
          alignItems={'start'}
        >
          <Grid item container spacing={2} xs={12} sm={4}>
            <Grid item sm={12}>
              <RenderField
                field={{
                  label: 'Invoice date',
                  type: 'datePicker',
                  name: 'invoiceDate',
                  allowPastDates: false,
                  allowFutureDates: true,
                  readOnly: isFiledReadOnly('invoiceDate'),
                }}
                formik={formik}
                size="small"
                isLoading={isLoading}
                onChange={(name, value) => {
                  if (!value) return
                  const paymentTerms =
                    Number(formik.values?.businessEntity?.paymentTerms) || 0
                  const newDate = addDays(value, paymentTerms)
                  formik.setFieldValue('dueDate', newDate)
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <RenderField
                field={{
                  label: 'Due date',
                  type: 'datePicker',
                  name: 'dueDate',
                  allowPastDates: false,
                  allowFutureDates: true,
                  readOnly: readOnly,
                }}
                formik={formik}
                size="small"
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteWithCreate
                label="Currency *"
                name="currency"
                readOnly={isFiledReadOnly('currency')}
                formik={formik}
                options={CurrenciesOptions}
                allowCreate={false}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              {showExchangeRate && (
                <RenderField
                  field={{
                    name: 'conversionRate',
                    label: 'Exchange Rate',
                    type: 'number',
                    readOnly: readOnly,
                  }}
                  formik={formik}
                  size="small"
                  decimalScale={4}
                  isLoading={isLoading}
                />
              )}
            </Grid>
          </Grid>
          <Grid item container spacing={2} xs={12} sm={4}>
            <Grid item xs={12}>
              <RenderField
                field={{
                  label: 'Invoice no.',
                  type: 'text',
                  name: 'invoiceNumber',
                  allowFutureDates: false,
                  allowPastDates: true,
                  readOnly: isFiledReadOnly('invoiceNumber'),
                }}
                formik={formik}
                size="small"
                isLoading={isLoading}
              />
            </Grid>

            <Grid item xs={12}>
              <AutocompleteWithCreate
                label="Mode of transport"
                // readOnly={readOnly}
                name="mot"
                formik={formik}
                options={MOTOptions}
                allowCreate={false}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <RenderField
                field={{
                  label: ' Description',
                  type: 'editor',
                  name: 'description',
                  // readOnly: readOnly,
                }}
                rows={2}
                formik={formik}
                size="small"
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2} xs={12} sm={4}>
            <Grid item xs={12}>
              <CustomerAutocomplete
                name="customerId"
                formik={formik}
                readOnly={isFiledReadOnly('customerId')}
                size="small"
                onChange={() => {
                  formik.setFieldValue('businessEntityId', '')
                  formik.setFieldValue('contactId', '')
                  formik.setFieldValue('businessEntity', null)
                }}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <BusinessEntitiesAutocomplete
                formik={formik}
                customerId={formik.values.customerId?.id}
                name="businessEntityId"
                size="small"
                readOnly={isFiledReadOnly('businessEntityId')}
                onChange={(el: any) => {
                  formik.setFieldValue('businessEntity', el)

                  return formik.setFieldValue(
                    'clientNumber',
                    `${el?.clientNumber || ''}`,
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <StaticContactsAutocomplete
                formik={formik}
                name="contactId"
                readOnly={readOnly}
                filterByCustomerId={`customerId=eq:${formik.values?.customerId?.id}`}
                size="small"
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <RenderField
                field={{
                  label: 'Customer reference no.',
                  type: 'text',
                  name: 'customerReference',
                  // readOnly: readOnly,
                }}
                formik={formik}
                size="small"
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <RenderField
                field={{
                  label: 'Client no.',
                  type: 'text',
                  name: 'clientNumber',
                  readOnly: isFiledReadOnly('clientNumber'),
                }}
                formik={formik}
                size="small"
                isLoading={isLoading}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} mt={2} mb={2}>
            <Divider />
          </Grid>
          {/* lineItems */}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(providedDroppable) => (
                <Grid
                  container
                  mt={1}
                  pl={1}
                  sm={12}
                  sx={{
                    width: '100%',
                    borderLeft: `2px solid ${theme.palette.warning.main}`,
                  }}
                  {...providedDroppable.droppableProps}
                  ref={providedDroppable.innerRef}
                >
                  <Grid item sm={12} mb={4}>
                    <Typography variant="h6">Service Items</Typography>
                  </Grid>
                  <FieldArray name="lineItems">
                    {({ push, remove, insert }) => (
                      <Grid item xs={12} container spacing={1}>
                        {formik.values?.lineItems?.map(
                          (item: any, index: number) => (
                            <Draggable
                              key={item.id}
                              draggableId={'row' + index}
                              index={index}
                            >
                              {(providedDraggable) => (
                                <Grid
                                  item
                                  xs={12}
                                  container
                                  display={'flex'}
                                  flexDirection={'row'}
                                  alignContent={'center'}
                                  alignItems={'center'}
                                  my={2}
                                  spacing={1}
                                  key={item.id}
                                  className={classes.itemGrid}
                                >
                                  <Grid
                                    item
                                    xs={0.5}
                                    m={0}
                                    alignSelf={'center'}
                                    {...providedDraggable.dragHandleProps}
                                  >
                                    <Chip
                                      label={index + 1}
                                      ref={providedDraggable.innerRef}
                                      {...providedDraggable.draggableProps}
                                    />
                                  </Grid>
                                  <Grid item xs={10.5} container spacing={1}>
                                    <Grid item xs={12} sm={2}>
                                      <AutocompleteWithCreate
                                        label="Service Category *"
                                        name={`lineItems[${index}].serviceType`}
                                        // readOnly={readOnly}
                                        formik={formik}
                                        options={servicesOptions}
                                        allowCreate={false}
                                        size="small"
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                      <AutocompleteWithCreate
                                        label="Service Item *"
                                        name={`lineItems[${index}].serviceTypeItem`}
                                        formik={formik}
                                        options={serviceTypeItemOptions(item)}
                                        allowCreate={false}
                                        size="small"
                                        onChange={(element) => {
                                          if (
                                            !formik.values.lineItems[index]
                                              .serviceType
                                          ) {
                                            findParentItem(element)?.id
                                              ? formik.setFieldValue(
                                                  `lineItems[${index}].serviceType`,
                                                  findParentItem(element)?.id,
                                                )
                                              : ''
                                          }
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={1.5}>
                                      <RenderField
                                        field={{
                                          label: 'Unit Price *',
                                          type: 'number',
                                          name: `lineItems[${index}].unitPrice`,
                                          readOnly:
                                            isFiledReadOnly('unitPrice'),
                                        }}
                                        noDecimal={true}
                                        formik={formik}
                                        size="small"
                                        allowNegative
                                        isLoading={isLoading}
                                        prefix={currencySymbol}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={1}>
                                      <RenderField
                                        field={{
                                          label: 'Quantity *',
                                          type: 'number',
                                          name: `lineItems[${index}].quantity`,
                                          readOnly: isFiledReadOnly('quantity'),
                                        }}
                                        formik={formik}
                                        size="small"
                                        noDecimal={false}
                                        decimalScale={3}
                                        isLoading={isLoading}
                                      />
                                    </Grid>{' '}
                                    <Grid item xs={12} sm={2}>
                                      <AutocompleteWithCreate
                                        label="Unit of Measure *"
                                        name={`lineItems[${index}].uom`}
                                        formik={formik}
                                        options={UOMOptions}
                                        allowCreate={false}
                                        size="small"
                                        // readOnly={readOnly}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                      <RenderReadOnlyNumberField
                                        value={calculateRowTotal({
                                          unitPrice:
                                            formik.values.lineItems[index]
                                              .unitPrice,
                                          quantity:
                                            formik.values.lineItems[index]
                                              .quantity,
                                        })}
                                        label={'Total'}
                                        size="small"
                                        name={`lineItems[${index}].rowTotal`}
                                        allowNegative
                                        prefix={currencySymbol}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={11.5}>
                                      <RenderField
                                        field={{
                                          label: 'Description',
                                          type: 'editor',
                                          name: `lineItems[${index}].description`,
                                          // readOnly,
                                        }}
                                        formik={formik}
                                        size="small"
                                        rows={4}
                                        isLoading={isLoading}
                                      />
                                    </Grid>
                                  </Grid>
                                  {!readOnly && (
                                    <Grid
                                      item
                                      alignSelf={'center'}
                                      sm={1}
                                      xs={12}
                                      display={'flex'}
                                      justifySelf={'end'}
                                      justifyContent={'end'}
                                      flexWrap={'nowrap'}
                                      alignContent={'center'}
                                    >
                                      <ButtonGroup
                                        variant="outlined"
                                        aria-label="Basic button group"
                                        size="small"
                                      >
                                        <Button
                                          id="sales-quotes-form-dublicate"
                                          onClick={() => {
                                            insert(
                                              index,
                                              formik.values?.lineItems[index],
                                            )
                                          }}
                                          color="primary"
                                        >
                                          <MoveDownIcon />
                                        </Button>
                                        <Button
                                          id="sales-quotes-form-delete"
                                          onClick={() => {
                                            remove(index)
                                          }}
                                          color="error"
                                          aria-label="delete"
                                          disabled={
                                            !formik.values?.lineItems?.length
                                          }
                                        >
                                          <DeleteIcon />
                                        </Button>
                                      </ButtonGroup>
                                    </Grid>
                                  )}
                                  {index !==
                                    formik.values?.lineItems?.length - 1 && (
                                    <Grid item xs={12}>
                                      <Divider />
                                    </Grid>
                                  )}
                                </Grid>
                              )}
                            </Draggable>
                          ),
                        )}
                        {!readOnly && (
                          <Grid
                            item
                            xs={12}
                            justifyContent={'center'}
                            mb={1}
                            alignSelf={'center'}
                          >
                            <Button
                              id="sales-quotes-form-add"
                              onClick={() => push({ id: uuidv4() })}
                              color="primary"
                              startIcon={<AddCircleIcon />}
                            >
                              Add Item
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </FieldArray>
                </Grid>
              )}
            </Droppable>
          </DragDropContext>

          {/* footer 1 */}
          <Grid container spacing={2} mb={1} mt={1}>
            <Grid item sm={9}></Grid>

            <Grid item sm={3}>
              <Divider sx={{ mb: 2 }} />
              <RenderReadOnlyNumberField
                value={calculateSubtotal(formik.values)}
                label={'SubTotal'}
                name={`subTotal`}
                prefix={currencySymbol}
                allowNegative
              />
            </Grid>
            {/* <Grid item sm={9}></Grid> */}
            {/* <Grid item sm={3}>
              <RenderField
                field={{
                  label: 'Discount',
                  type: 'number',
                  name: 'discount',
                  max: 100,
                  min: 0,
                  readOnly,
                }}
                suffix={'%'}
                formik={formik}
                size="small"
              />
            </Grid> */}
            <Grid item sm={9}></Grid>

            <Grid item sm={3}>
              <AutocompleteWithCreate
                label="Tax rate *"
                name="taxRate"
                formik={formik}
                options={taxesOptions as Option[]}
                allowCreate={false}
                readOnly={isFiledReadOnly('taxRate')}
              />
            </Grid>

            <Grid item sm={9}></Grid>

            <Grid item sm={3}>
              <RenderReadOnlyNumberField
                value={calculateFinalTotal({
                  ...formik.values,
                  taxtPercentage,
                })}
                label={'Total'}
                name={`subTotal`}
                prefix={currencySymbol}
                allowNegative
              />
            </Grid>
            {showExchangeRate && (
              <>
                <Grid item sm={9}></Grid>
                <Grid item sm={3}>
                  <RenderReadOnlyNumberField
                    value={
                      calculateFinalTotal({
                        ...formik.values,
                        taxtPercentage,
                      }) * formik.values.conversionRate
                    }
                    label={'Total ' + officeData?.currency}
                    name={`totalInOfficeCurrency`}
                    prefix={currencySymbol}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}

export default InvoiceForm
