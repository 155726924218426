/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-hot-toast'
import {
  CmrMutationProp,
  OperationsMutationProp as mutationProp,
} from 'src/common/types/Operation'
import { get, post, put } from 'src/common/utils/api'
import { useState } from 'react'
import { invalidateQueriesWithDelay } from 'src/common/utils/wait'
import { useAuth } from 'src/common/hooks/use-auth'

const convertToOption = (obj: any, labelKey?: string) => {
  if (!obj) return null
  return {
    ...obj,
    value: obj.id,
    label: obj[labelKey || 'name'],
  }
}

export const useGetShipementDetails = ({ fileId }: { fileId: string }) => {
  return useQuery(['file/shipment', fileId], {
    queryFn: () => get(`operations/files/${fileId}/shipment`), //operations/files/fileID/shipment
    enabled: !!fileId,
    retry: false,
    select: (data: any) => {
      const transformShipper =
        data.shipper?.length > 0
          ? {
              ...data.shipper[0],
              value: data.shipper[0].id,
              label: data.shipper[0].name,
            }
          : null
      const transformConsignee =
        data.consignee?.length > 0
          ? {
              ...data.consignee[0],
              value: data.consignee[0].id,
              label: data.consignee[0].name,
            }
          : null
      const transformNotifyParty =
        data.notifyParty?.length > 0
          ? {
              ...data.notifyParty[0],
              value: data.notifyParty[0].id,
              label: data.notifyParty[0].name,
            }
          : null
      const comodityProductsList = Object.values(
        data.commodity?.shipmentProducts || {},
      )
      const comodityCollisList = Object.values(data.commodity?.collis || {})

      const transformCommodity = data.commodity && {
        ...data.commodity,

        shipmentProducts: comodityProductsList?.map((item: any) => ({
          ...item,
          materialValue: item.value,
          productNumber: {
            ...item?.productNumber,
            value: item?.productNumber?.id,
            label: item?.productNumber?.partNumber,
          },
        })),
        collis: comodityCollisList,
      }

      const transformTransportation = {
        ...data?.transportation,
        closingVGM: data?.transportation?.closingVGM,
        closingIMO: data?.transportation?.closingIMO,
        carrier: convertToOption(data.transportation?.carrier),
        dischargeAddress: convertToOption(
          data.transportation?.dischargeAddress,
        ),
        loadingAddress: convertToOption(data.transportation?.loadingAddress),
        preCarriageCarrier: convertToOption(
          data.transportation?.preCarriageCarrier,
        ),
        importTerminalPickupAddress: convertToOption(
          data.transportation?.importTerminalPickupAddress,
        ),
        emptyDeliveryDepot: convertToOption(
          data.transportation?.emptyDeliveryDepot,
        ),
        blNumber: data.transportation?.blNumber
          ? data.transportation?.blNumber?.scac +
            data.transportation?.blNumber?.digits
          : '',
        dischargePort: convertToOption(data.transportation?.dischargePort),
        loadingPort: convertToOption(data.transportation?.loadingPort),
        airLegs: data.transportation?.legs?.map((leg: any) => ({
          ...leg,
          dischargePort: convertToOption(leg?.dischargePort),
          loadingPort: convertToOption(leg?.loadingPort),
        })),
        seaLegs: data.transportation?.legs?.map((leg: any) => ({
          ...leg,
          dischargePort: convertToOption(leg?.dischargePort),
          loadingPort: convertToOption(leg?.loadingPort),
          vesselName: convertToOption(leg?.vesselName),
        })),
        containers: data.transportation?.containers?.map((container: any) => ({
          ...container,
          addresses: container.addresses?.map((address: any) => ({
            ...address,
            dischargeAddress: convertToOption(address.dischargeAddress),
            loadingAddress: convertToOption(address.loadingAddress),
          })),
          fullPickupTerminal: convertToOption(container?.fullPickupTerminal),
          fullDeliveryTerminal: convertToOption(
            container?.fullDeliveryTerminal,
          ),
          products: comodityProductsList?.map((itm: any) => ({
            [itm?.productNumber?.id]: container?.products?.includes(
              itm?.productNumber?.id,
            ),
          })),
          rawCollis: container.collis,
          rawProducts: container.products,
          emptyReturnDepot: convertToOption(container?.emptyReturnDepot),
          emptyPickupDepot: convertToOption(container?.emptyPickupDepot),

          preCarriageCarrier: convertToOption(container?.preCarriageCarrier),

          collis: comodityCollisList.map((colli: any) => {
            const currentContainerColli = container.collis?.find(
              (cnt: any) => cnt.id === colli.id,
            )

            if (currentContainerColli) {
              return { ...colli, ...currentContainerColli }
            } else {
              return { ...colli, quantity: 0 }
            }
          }),
        })),
        trucks: data.transportation?.trucks?.map((container: any) => ({
          ...container,
          addresses: container.addresses?.map((address: any) => ({
            ...address,
            dischargeAddress: convertToOption(address.dischargeAddress),
            loadingAddress: convertToOption(address.loadingAddress),
          })),

          products: comodityProductsList?.map((itm: any) => ({
            [itm?.productNumber?.id]: container?.products?.includes(
              itm?.productNumber?.id,
            ),
          })),
          rawProducts: container.products,
          carrier: convertToOption(container?.carrier),

          rawCollis: container.collis,
          collis: comodityCollisList.map((colli: any) => {
            const currentContainerColli = container.collis?.find(
              (cnt: any) => cnt.id === colli.id,
            )

            if (currentContainerColli) {
              return { ...colli, ...currentContainerColli }
            } else {
              return { ...colli, quantity: 0 }
            }
          }),
        })),
      }

      return {
        ...data,
        shipper: transformShipper,
        consignee: transformConsignee,
        notifyParty: transformNotifyParty,
        boltricsReferences: data.whmsReference
          .split(',')
          .map((x: string) => ({ value: x })),
        commodity: transformCommodity,
        transportation: transformTransportation,
      }
    },
  })
}
export const useGetCustomerShipementDetails = ({
  fileId,
}: {
  fileId: string
}) => {
  const { user } = useAuth()

  return useQuery(['customer/file/shipment', user?.customerId, fileId], {
    queryFn: () =>
      get(`customers/${user?.customerId}/orders/${fileId}/shipment`, {}, false),
    enabled: !!fileId,
    retry: false,
    onError: (e: any) => {
      console.log('error', e)
      return null
    },
  })
}

export const useGetTransportOrderDocuments = ({
  shipmentId,
}: {
  shipmentId: string
}) => {
  return useQuery(['transport-order-documentst', shipmentId], {
    queryFn: () =>
      get(`operations/shipments/${shipmentId}/transport-order-documents`), //operations/files/fileID/shipment
    enabled: !!shipmentId,
    retry: false,
  })
}

export const useCreateShipmentMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: any
      setFieldError?: (field: string, value: string | undefined) => void
    }) => {
      return post('operations/shipments', values, setFieldError)
    },

    onSuccess: (data: any, variables: any) => {
      toast.success(`Shipment was created successfully`)
      try {
        variables?.values?.fileId &&
          queryClient.invalidateQueries([
            'file/shipment',
            variables?.values?.fileId,
          ])
        callback && callback()
      } catch {
        console.log('error')
      }
    },
    onError: (error: any) => {
      console.log(error)
      toast.error(`Shipment could not be created! Please try again`)
    },
  })
}

export const useUpdateShipmentMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: any
      setFieldError?: (field: string, value: string | undefined) => void
    }) => {
      return put('operations/shipments/' + values.id, values, setFieldError)
    },

    onSuccess: (data: any, variables: any) => {
      toast.success(`Shipment was updated successfully`)
      try {
        queryClient.invalidateQueries([
          'file/shipment',
          variables?.values?.fileId,
        ])
        callback && callback()
      } catch {
        console.log('error')
      }
      callback && callback()
    },
    onError: (response: Error, var2: any) => {
      console.log({ response, var2 })
      const error = (response?.message as string) || ''
      toast.error(`Shipment could  not be updated! ${error}`)
      // callback && callback()
    },
  })
}

export const useCreateCMRMutation = ({
  callback,
  shipmentId,
}: CmrMutationProp) => {
  const queryClient = useQueryClient()
  const [isLoadingGeneral, setIsLoadingComplete] = useState(false)

  const { mutate, isError, error } = useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: any
      setFieldError?: (field: string, value: string | undefined) => void
    }) => {
      toast.loading('Preparing CMR')

      return post(
        `operations/shipments/${shipmentId}/generate-cmr`,
        values,
        setFieldError,
      )
    },
    onMutate: () => {
      setIsLoadingComplete(true)
    },
    onSuccess: async (_data: any, variables: any) => {
      callback && callback()

      await setTimeout(() => {
        try {
          variables?.values?.fileId &&
            queryClient.invalidateQueries([
              'file/shipment',
              variables?.values?.fileId,
            ])
        } catch {
          toast.dismiss()
          toast.error(`Shipment CMR could not be fetched! Please refresh page!`)
        } finally {
          setIsLoadingComplete(false)
          toast.dismiss()
          toast.success(
            `CMR was created successfully. Click Print icon to print it`,
          )
        }
      }, 1000)
    },
    onError: () => {
      toast.error(`Shipment CMR could not be created! Please try again`)
      setIsLoadingComplete(false)
    },
  })

  const effectiveLoading = isLoadingGeneral

  return { mutate, isLoading: effectiveLoading, isError, error }
}

interface mutationComodityProp {
  shipmentId: string | null
  callback?: (data?: unknown) => void
}

export const useCreateShipmentComodityMutation = ({
  shipmentId,
  callback,
}: mutationComodityProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: any
      setFieldError?: (field: string, value: string | undefined) => void
    }) => {
      return post(
        `operations/shipments/${shipmentId}/commodity`,
        values,
        setFieldError,
      )
    },

    onSuccess: (data: any, variables: any) => {
      toast.success(`Commodity was saved successfully`)
      queryClient.invalidateQueries([
        'file/shipment',
        variables?.values?.fileId,
      ])

      callback && callback()
    },
    onError: (var1: any, var2: any) => {
      console.log({ var1, var2 })
      toast.error(`Commodity could not be saved! Please try again`)
      // callback && callback()
    },
  })
}

export const useCreateShipmentTransportatinMutation = ({
  shipmentId,
  callback,
}: mutationComodityProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: any
      setFieldError?: (field: string, value: string | undefined) => void
    }) => {
      return post(
        `operations/shipments/${shipmentId}/transportation`,
        values,
        setFieldError,
      )
    },

    onSuccess: (data: any, variables: any) => {
      switch (variables?.values?.deletionType) {
        case 'truck_delete': {
          toast.success(`Truck was deleted successfully`)
          break
        }
        default: {
          toast.success(`Transportation was saved successfully`)
        }
      }
      queryClient.invalidateQueries([
        'file/shipment',
        variables?.values?.fileId,
      ])
      callback && callback()
    },
    onError: () => {
      toast.error(
        `Transportation could not be saved! Please make sure you click the Save button again`,
      )
    },
  })
}

export const useCreateTODMutation = ({
  shipmentId,
  callback,
}: mutationComodityProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (values: any) => {
      toast.loading('Preparing PDF')
      return post(
        `operations/shipments/${shipmentId}/generate-transport-order-document`,
        values,
      )
    },

    onSuccess: (data: any, variables: any) => {
      toast.dismiss()
      toast.success(
        `Transportation order document has been generated successfully`,
      )

      invalidateQueriesWithDelay(
        queryClient,
        ['file/shipment', variables.fileId],
        1000,
      )
      callback && callback()
    },
    onError: () => {
      toast.dismiss()
      toast.error(
        `Transportation Order Document could not be saved! Please try again`,
      )
      // callback && callback()
    },
  })
}
interface Error {
  message: string
}
