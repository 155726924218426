import {
  Box,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Container,
  Divider,
} from '@mui/material'
import { formatDate } from 'src/common/utils/date-locale'
import { TAXES_OPTIONS } from 'src/common/apis'
import { NumericFormatText } from 'src/common/components/form/RenderField'
import { useParams } from 'react-router-dom'
import { LineItem } from 'src/common/types/Quote'
import { useTheme } from '@mui/material/styles'
import Button from 'src/common/components/loadingButton'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { useRouter } from 'src/common/hooks/use-router'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useGetInvoicePreviewDetails } from './useRequests'
import { calculateRowTotal } from '../quotes/form/utils'

const PreviewPage = () => {
  const { invoiceId = '' } = useParams()
  const invoiceQuery = useGetInvoicePreviewDetails(invoiceId || '')
  const invoiceData = invoiceQuery?.data
  const businessEntity = invoiceData?.businessEntity
  const currencySymbol = invoiceData?.currency?.currencySign
  const office = invoiceData?.office
  const theme = useTheme()
  const router = useRouter()

  const contact = invoiceData?.contact ? invoiceData.contact.name : ''

  const vatPercentage = TAXES_OPTIONS.find(
    (option) => option.value === invoiceData?.taxRate,
  ) || { label: '' }

  const footerNotes: string[] =
    Object.values(office?.footerData?.appendinx || {}) || []

  const footerBanks: string[] =
    Object.values(
      office?.footerData?.value?.bankAccounts ||
        office?.footerData?.bankAccounts ||
        {},
    ) || []

  const hasOfficeLogo =
    office?.logo &&
    office?.logo.length &&
    office?.logo[0] &&
    office?.logo[0]?.path

  return (
    <Box component="main" py={4}>
      <Container maxWidth="md">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            id="sales-invoices-cancel"
            variant="text"
            onClick={() => {
              router.push(`/invoices`)
            }}
            startIcon={<ArrowBackIcon />}
            sx={{ mb: 2 }}
            size="small"
          >
            Back
          </Button>
          <Button
            id="sales-quotes-download"
            variant="outlined"
            onClick={() => {
              window.open(invoiceData?.pdfUrl)
            }}
            startIcon={<ArrowDownwardIcon />}
            sx={{ mb: 2, height: '50px' }}
            size="small"
          >
            PDF
          </Button>
        </Box>
        <Card sx={{ p: 6, mb: 0, pb: 0 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Box>
              <Typography variant="h3" sx={{ pb: 2 }}>
                INVOICE
              </Typography>
              <Typography sx={{ pb: 0.5 }}>
                No. {invoiceData?.reference}
              </Typography>
              <Typography>Ref. {invoiceData?.reference}</Typography>
            </Box>

            <Box
              sx={{
                display: 'inline-flex',
                height: 150,
                width: 150,
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              {hasOfficeLogo ? (
                <img
                  src={office?.logo[0]?.path}
                  alt="logo"
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              ) : (
                <img
                  src="/assets/rulewave/Rulewave-logo-global.JPG"
                  alt="logo"
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              )}
            </Box>
          </Stack>
          <Box mt={1}>
            <Grid
              sx={{ fontSize: '14px' }}
              container
              justifyContent="space-between"
            >
              <Grid sm={4}>
                <Typography variant="body2">
                  <b>From</b>
                  <br />
                  {office?.name || 'Rulewave'}
                  <br />
                  {office?.street || 'Energieweg 2'}
                  <br />
                  {office?.zipCode || '4791 RN'},{' '}
                  {office?.city || 'Klundert (Moerdijk)'}
                  <br />
                  {office?.countryLabel || 'Netherlands'}
                  <Grid
                    container
                    rowSpacing={0.5}
                    columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                    mt={1}
                  >
                    {office?.contactDetails?.phone && (
                      <>
                        <Grid item xs={3}>
                          <b>Phone</b>
                        </Grid>
                        <Grid item xs={9}>
                          {office?.contactDetails?.phone}{' '}
                        </Grid>
                      </>
                    )}
                    {office?.contactDetails?.email && (
                      <>
                        <Grid item xs={3}>
                          <b>Email</b>
                        </Grid>
                        <Grid item xs={9}>
                          {office?.contactDetails?.email}
                        </Grid>
                      </>
                    )}

                    <Grid item xs={3}>
                      <b>Web</b>
                    </Grid>
                    <Grid item xs={9}>
                      www.rulewave.com
                    </Grid>
                    {office?.footerData?.taxId && (
                      <>
                        <Grid item xs={3}>
                          <b>VAT</b>
                        </Grid>
                        <Grid item xs={9}>
                          {office?.footerData?.taxId}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Typography>
              </Grid>
              <Grid sm={4.5}>
                <Typography variant="body2">
                  <b>Billed to:</b>
                  <br />
                  {businessEntity?.companyName}
                  <br />
                  {businessEntity?.address?.street}
                  <br />
                  {businessEntity?.address?.zipCode},{' '}
                  {businessEntity?.address?.city}
                  <br />
                  {invoiceData?.countryLabel}
                </Typography>

                <Grid
                  container
                  rowSpacing={0.5}
                  columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                  mt={1}
                >
                  {contact?.name && (
                    <>
                      <Grid item xs={3}>
                        <b>Attn</b>
                      </Grid>
                      <Grid item xs={9}>
                        {contact?.name}
                      </Grid>
                    </>
                  )}
                  {businessEntity?.phone && (
                    <>
                      <Grid item xs={3}>
                        <b>Phone</b>
                      </Grid>
                      <Grid item xs={9}>
                        {businessEntity?.phone}
                      </Grid>
                    </>
                  )}

                  {businessEntity?.email && (
                    <>
                      <Grid item xs={3}>
                        <b>Email</b>
                      </Grid>
                      <Grid item xs={9}>
                        {businessEntity?.email}
                      </Grid>
                    </>
                  )}

                  {businessEntity?.taxId && (
                    <>
                      <Grid item xs={3}>
                        <b>VAT</b>
                      </Grid>
                      <Grid item xs={9}>
                        {businessEntity?.taxId}
                      </Grid>
                    </>
                  )}
                  {businessEntity?.clientNumber && (
                    <>
                      <Grid item xs={3}>
                        <b>Client No.</b>
                      </Grid>
                      <Grid item xs={9}>
                        {businessEntity?.clientNumber}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid md={3.5}>
                <Grid
                  container
                  rowSpacing={0.5}
                  columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                  mt={1}
                >
                  <Grid item xs={4}>
                    <b>Issued</b>
                  </Grid>
                  <Grid item xs={8}>
                    {formatDate(invoiceData?.createdAt)}
                  </Grid>
                  <Grid item xs={4}>
                    <b>Due</b>
                  </Grid>
                  <Grid item xs={8}>
                    {formatDate(invoiceData?.dueDate)}
                  </Grid>
                  <Grid item xs={4}>
                    <b>Currency</b>
                  </Grid>
                  <Grid item xs={8}>
                    {invoiceData?.currency?.currencyCode}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mt: 2 }} />
          <Box
            sx={{
              my: 2,
              py: 2,
              mb: 2,
              mx: -6,
              px: 6,
              backgroundColor:
                theme.palette.mode === 'light' ? '#eee' : '#0c131f',
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              sx={{ fontSize: '14px' }}
            >
              <Grid md={5}>
                <Grid
                  container
                  rowSpacing={0.5}
                  columnSpacing={{ xs: 0.5, sm: 1, md: 0.5 }}
                  mt={1}
                >
                  {invoiceData?.vessel && (
                    <>
                      <Grid item xs={5}>
                        <b>Vessel:</b>
                      </Grid>
                      <Grid item xs={7}>
                        {invoiceData?.vessel?.name}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={5}>
                    <b>POL + ETD:</b>{' '}
                  </Grid>
                  <Grid item xs={7}>
                    {invoiceData?.dischargeAddress?.name}{' '}
                    {formatDate(invoiceData?.estimatedTimeOfDeparture)}
                  </Grid>
                  <Grid item xs={5}>
                    <b>POD + ETA:</b>{' '}
                  </Grid>
                  <Grid item xs={7}>
                    {invoiceData?.loadingAddress?.name}{' '}
                    {formatDate(invoiceData?.estimatedTimeOfArrival)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid md={4}>
                <Grid
                  container
                  rowSpacing={0.5}
                  columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                  mt={1}
                >
                  {invoiceData?.totals?.totalHandlingUnits ? (
                    <>
                      <Grid item xs={5}>
                        <b>Total Handling Units:</b>
                      </Grid>
                      <Grid item xs={7}>
                        <NumericFormatText
                          value={invoiceData?.totals?.totalHandlingUnits}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}

                  {invoiceData?.totals?.totalGrossWeight ? (
                    <>
                      <Grid item xs={5}>
                        <b>Total gross weight:</b>{' '}
                      </Grid>
                      <Grid item xs={7}>
                        <NumericFormatText
                          value={invoiceData?.totals?.totalGrossWeight}
                          displayType={'text'}
                          thousandSeparator={true}
                          suffix={' ' + invoiceData?.totals?.grossWeightUnit}
                          fixedDecimalScale={true}
                        />
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}
                  {invoiceData?.totals?.totalVolume ? (
                    <>
                      <Grid item xs={5}>
                        <b>Total volume:</b>{' '}
                      </Grid>
                      <Grid item xs={7}>
                        <NumericFormatText
                          value={invoiceData?.totals?.totalVolume}
                          displayType={'text'}
                          thousandSeparator={true}
                          suffix={' ' + invoiceData?.totals?.volumeUnit}
                          fixedDecimalScale={true}
                        />
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
              <Grid xs={3} md={3}>
                <Grid
                  container
                  rowSpacing={0.5}
                  columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                  mt={1}
                >
                  {invoiceData?.mot && (
                    <>
                      <Grid item xs={5}>
                        <b>MOT:</b>
                      </Grid>
                      <Grid item xs={7}>
                        {invoiceData?.mot?.toUpperCase()}
                      </Grid>
                    </>
                  )}
                  {invoiceData?.mot === 'sea' && invoiceData?.blNumber && (
                    <Grid item xs={5}>
                      <b>B/L:</b>{' '}
                    </Grid>
                  )}
                  {invoiceData?.mot === 'sea' && invoiceData?.blNumber && (
                    <Grid item xs={7}>
                      {invoiceData?.blNumber}
                    </Grid>
                  )}
                  {invoiceData?.mot === 'air' && invoiceData?.awb && (
                    <Grid item xs={5}>
                      <b>AWB#:</b>{' '}
                    </Grid>
                  )}
                  {invoiceData?.mot === 'air' && invoiceData?.awb && (
                    <Grid item xs={7}>
                      {invoiceData?.awb}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '50%' }}>We charge you for</TableCell>
                <TableCell>Unit Price</TableCell>
                <TableCell sx={{ width: '75px' }}>Quantity</TableCell>
                <TableCell align="right">UOM</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceData?.invoiceItems.map(
                (item: LineItem, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="body2">
                        {item.serviceTypeItem
                          ?.replace(/-/g, ' ')
                          .replace(/\b\w/g, (char) => char.toUpperCase()) || ''}
                        <br />
                        {
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.description || '',
                            }}
                          />
                        }
                      </Typography>
                    </TableCell>{' '}
                    <TableCell>
                      <NumericFormatText
                        value={item.unitPrice}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        fixedDecimalScale={true}
                      />
                    </TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell align="right">{item.uom}</TableCell>
                    <TableCell align="right">
                      <NumericFormatText
                        value={calculateRowTotal(item)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        fixedDecimalScale={true}
                      />
                    </TableCell>
                  </TableRow>
                ),
              )}{' '}
              <TableRow>
                <TableCell colSpan={2} sx={{ borderBottom: '0px' }}></TableCell>
                <TableCell colSpan={2}>
                  <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
                    Subtotal
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    <NumericFormatText
                      value={invoiceData?.subtotal}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={currencySymbol}
                      fixedDecimalScale={true}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} sx={{ borderBottom: '0px' }}></TableCell>

                <TableCell colSpan={2}>
                  <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
                    VAT
                    {vatPercentage?.label ? `(${vatPercentage?.label})` : ''}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    <NumericFormatText
                      value={invoiceData?.taxAmount}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={currencySymbol}
                      fixedDecimalScale={true}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} sx={{ borderBottom: '0px' }}></TableCell>
                <TableCell colSpan={2}>
                  <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
                    Total
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    <NumericFormatText
                      value={invoiceData?.total}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={currencySymbol}
                      fixedDecimalScale={true}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {invoiceData?.status !== 'draft' && (
            <Typography>
              This invoice has to be paid within {businessEntity?.paymentTerms}{' '}
              days
            </Typography>
          )}
          <Grid sm={8} sx={{ pt: 5 }}>
            {footerNotes.map((item: string, index: number) => (
              <Typography
                fontSize={'small'}
                key={index}
                dangerouslySetInnerHTML={{
                  __html: item || '',
                }}
              ></Typography>
            ))}
          </Grid>
          <Box
            sx={{
              mt: 3,
              mb: 0,
              py: 3,
              mx: -6,
              px: 6,
              height: 'auto',
              backgroundColor:
                theme.palette.mode === 'light' ? '#89CFF0' : '#191d36',
            }}
          >
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <strong>Bank account details:</strong>
                {footerBanks.map((item: string, index: number) => (
                  <Typography
                    fontSize={'small'}
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: item || '',
                    }}
                  ></Typography>
                ))}
              </Grid>
              <Grid item sm={4}>
                <small style={{ fontSize: '9px' }}>
                  Disclaimer: Please state the invoice number with payment.
                  Please note: This invoice contains confidential information
                  intended solely for the use of the individual or entity to
                  whom it is addressed, and any unauthorized review, use,
                  disclosure, or distribution is prohibited.
                </small>
              </Grid>
              <Grid item sm={4} sx={{ textAlign: 'center' }}>
                <strong>Global Logistics Service Provider</strong> <br />
                <small style={{ fontSize: '10px' }}>
                  The Netherlands | United States | Singapore <br /> Malaysia |
                  Dubai | Turkey | Azerbaijan | Kazakhstan
                </small>
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Divider sx={{ mt: 4 }} />
      </Container>
    </Box>
  )
}

export default PreviewPage
