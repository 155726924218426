import { combineReducers } from '@reduxjs/toolkit'
import { reducer as officeReducer } from '../slices/OfficeSlice'
import { reducer as filtersSlice } from '../slices/FiltersSlice'
import { reducer as sortingSlice } from '../slices/SortingSlice'
import { reducer as quoteFormReducer } from './../modules/quotes/redux/formSlice'
import { reducer as notificationReducer } from '../slices/NotificationSlice'
import { reducer as selectRowReducer } from '../slices/InfiniteGridSelectSlice'
// import { reducer as kanbanReducer } from '../slices/kanban'

export const rootReducer = combineReducers({
  offices: officeReducer,
  filters: filtersSlice,
  sorting: sortingSlice,
  quoteForm: quoteFormReducer,
  notifications: notificationReducer,
  selectRow: selectRowReducer,
  // kanban: kanbanReducer,
})
