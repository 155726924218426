/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Container,
  Divider,
  Alert,
  AlertTitle,
  Tooltip,
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {
  useGetPreviewQuoteQuery,
  useRejectQuoteMutation,
  useAcceptQuoteMutation,
  useUpdateQuoteMutation,
  useSendQuoteMutation,
  useHideQuoteMutation,
  useShowQuoteMutation,
} from '../useQuotesRequests'
import { calculateDateStatus, formatDate } from 'src/common/utils/date-locale'
import { TAXES_OPTIONS } from 'src/common/apis'
import { NumericFormatText } from 'src/common/components/form/RenderField'
import { useParams } from 'react-router-dom'
import {
  calculateChargableWeight,
  calculatePackagesTotal,
  calculatePackagesVolume,
  calculatePackagesWeight,
  calculateRowTotal,
  calculateTotalSubtotalDifference,
} from '../form/utils'
import { LineItem } from 'src/common/types/Quote'
import { useGetCustomerDetails } from 'src/modules/customers/useCustomersRequests'
import { useTheme } from '@mui/material/styles'
import Button from 'src/common/components/loadingButton'
import { useModal } from 'src/common/hooks/use-modal'
import ConfirmRejectDialog, { rejectReasons } from './confirmationRejectDialog'
import ConfirmExtendDateDialog from './confirmationExtendDateDialog'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { StickyFooter } from 'src/common/components/sticky-footer'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import ConfirmationDialog from 'src/common/components/dialogs/ConfirmationDialog'
import { useRouter } from 'src/common/hooks/use-router'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useAuth } from 'src/common/hooks/use-auth'
import { useGetCurrentOfficeData } from 'src/common/hooks/use-current-office-data'
import { STATUS, Status, StatusKey } from 'src/common/components/statuses'
import { useGetOfficeDateFormat } from 'src/common/hooks/use-get-office-date-format'
import { SvgIconProps } from '@mui/material'
import IButtonWithOptions from 'src/common/components/button-with-options-icon'
import { hasEmptyAddressFields } from 'src/common/utils/helpers'

const validColors: SvgIconProps['color'][] = [
  'error',
  'disabled',
  'warning',
  'action',
  'inherit',
  'primary',
  'secondary',
  'success',
  'info',
]

// Type guard function
function isValidColor(color: any): color is SvgIconProps['color'] {
  return (
    typeof color === 'string' &&
    validColors.includes(color as SvgIconProps['color'])
  )
}

const Address = ({ entity }: any) => {
  if (!entity) {
    return <></>
  }

  let separator = ''
  if (entity?.zipCode && (entity?.city || entity?.address?.city)) {
    separator = ', '
  }
  return (
    <div>
      {entity?.name || entity?.fullAddress}
      <br />
      {entity?.street || entity?.address?.street}
      <br />
      {entity?.zipCode || entity?.address?.zipCode}
      {separator}
      {entity?.city || entity?.address?.city}
      <br />
      {entity?.country || entity?.address?.country}
    </div>
  )
}

const PreviewQuote = () => {
  const { quoteId = '' } = useParams()
  const { user } = useAuth()
  const quoteQuery = useGetPreviewQuoteQuery(quoteId || '', user?.customerId)
  const quoteData = quoteQuery.data
  const currencySymbol =
    quoteData?.currencySymbol || quoteData?.currencyObj?.sign //useGetCurrencySymbol(quoteData?.currencySymbol || '')
  const termsList = quoteData?.terms || []
  const { dateFormat } = useGetOfficeDateFormat()
  const theme = useTheme()

  const {
    handleClose: handleCloseConfirmation,
    handleOpen: handleOpenConfirmation,
    open: openConfirmation,
  } = useModal()
  const {
    handleClose: handleCloseConfirmationExtendDate,
    handleOpen: handleOpenConfirmationExtendDate,
    open: openConfirmationExtendDate,
  } = useModal()

  const {
    handleClose: handleCloseConfirmationAccept,
    handleOpen: handleOpenConfirmationAccept,
    open: openConfirmationAccept,
  } = useModal()

  const currentOfficeQuery = useGetCurrentOfficeData()
  const office = quoteQuery.data?.office || currentOfficeQuery
  const customerQuery = useGetCustomerDetails(quoteData?.customer?.id || '')

  const rejectQuote = useRejectQuoteMutation({
    id: quoteId,
    callback: () => {
      handleCloseConfirmation()
      formik.resetForm()
    },
  })
  const updatemutation = useUpdateQuoteMutation({
    callback: handleCloseConfirmationExtendDate,
  })
  const accpptQuote = useAcceptQuoteMutation({
    id: quoteId,
    callback: () => {
      handleCloseConfirmationAccept()
    },
  })
  const sendQuoteMutation = useSendQuoteMutation({ id: quoteId })

  const hideQuoteMutation = useHideQuoteMutation({
    callback: () => handleCloseConfirmationHide(),
  })
  const showQuoteMutation = useShowQuoteMutation({
    callback: () => handleCloseConfirmationHide(),
  })
  const handleHide = () => {
    quoteQuery?.data?.isPrivate
      ? showQuoteMutation.mutate(quoteId as string)
      : hideQuoteMutation.mutate(quoteId as string)
  }

  const {
    handleClose: handleCloseConfirmationHide,
    handleOpen: handleOpenConfirmationHide,
    open: openConfirmationHide,
  } = useModal()

  const validationSchema = Yup.object().shape({
    rejectReason: Yup.string().required('Reason is required'),
    customReason: Yup.string().when('rejectReason', {
      is: (val: any) => val === 'Other',
      then: (schema: any) => schema.required('Reason is required'),
      otherwise: (schema: any) => schema.notRequired(),
    }),
  })

  const formik = useFormik({
    initialValues: {
      rejectReason: null,
      customReason: '',
      validUntil: quoteData?.validUntil,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values): Promise<void> => {
      let reason = values.rejectReason
      if (values.rejectReason === 'Other') reason = values.customReason as any
      rejectQuote.mutate({
        values: { rejectReason: reason },
        setFieldError: formik.setFieldError,
      })
    },
  })
  const quoteDataUpdate = {
    ...quoteData,

    contactId: quoteData?.contact?.id,
    customerId: quoteData?.customer?.id,

    pickupPort: quoteData?.pickUpPortObj?.id || null,
    deliveryPort: quoteData?.deliveryPortObj?.id,

    pickupAddress: hasEmptyAddressFields({ address: quoteData?.pickupAddress })
      ? null
      : quoteData?.pickupAddress,
    deliveryAddress: hasEmptyAddressFields({
      address: quoteData?.deliveryAddress,
    })
      ? null
      : quoteData?.deliveryAddress,
    validUntil: formatDate(formik.values.validUntil),
  }

  const handleConfirmExtendDate = () => {
    updatemutation.mutate({
      values: quoteDataUpdate,
    })
  }
  const router = useRouter()

  const { businessEntity = {}, contact = {} } = quoteData || {}

  const packages = quoteData?.packages || []

  const vatPercentage = TAXES_OPTIONS.find(
    (option) => option.value === quoteData?.taxRate,
  ) || { label: '' }

  if (quoteQuery?.isLoading || customerQuery?.isLoading) return null

  const packagesTotalQuantity = calculatePackagesTotal(packages)
  const packagesTotalVolume = calculatePackagesVolume(packages)
  const packagesTotalWeight = calculatePackagesWeight(packages)
  const chargeableWeight = calculateChargableWeight(packages)
  let incotermValue = quoteData?.incoTerm
    ? quoteData?.incoTerm.split('-')[0]
    : ''
  incotermValue = quoteData?.city
    ? incotermValue + ' - ' + quoteData?.city
    : incotermValue

  const footerNotes: string[] = office?.footerData?.value?.appendinx
    ? Object.values(office?.footerData?.value?.appendinx)
    : Object.values(office?.footerData?.appendinx || {}) || []

  const showGreyArea =
    quoteData?.mot ||
    quoteData?.incoTerm ||
    quoteData?.pickUpPort ||
    quoteData?.deliveryPort ||
    !hasEmptyAddressFields({ address: quoteData?.pickupAddress }) ||
    !hasEmptyAddressFields({ address: quoteData?.deliveryAddress })

  const hasOfficeLogo =
    office?.logo &&
    office?.logo.length &&
    office?.logo[0] &&
    office?.logo[0]?.path

  const dateStatus = quoteData?.validUntil
    ? calculateDateStatus(quoteData?.validUntil)
    : { isValid: true, color: 'disabled', message: '' }

  const isExpired = quoteData?.validUntil ? !dateStatus?.isValid : false
  const quoteStatus: StatusKey = quoteData?.status || 'pending'
  const isPending = quoteStatus === 'pending'
  const isFinal = quoteStatus === 'final'
  const date = quoteData?.validUntil
  const formattedValidUntilDate = formatDate(date, dateFormat)
  const noLongerValid = isPending && isExpired
  return (
    <Box component="main" py={4}>
      <Container maxWidth="md" sx={{ mb: 6 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            id="sales-quotes-cancel"
            variant="text"
            onClick={() => {
              router.push(`/quotes`)
            }}
            startIcon={<ArrowBackIcon />}
            sx={{ mb: 2, height: '50px' }}
          >
            Back
          </Button>
          {noLongerValid ? (
            <Box
              m={2}
              width={'50%'}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Alert variant="outlined" severity="error">
                This quote is no longer valid
                <br />
                This quote was valid until {formattedValidUntilDate}
              </Alert>
            </Box>
          ) : (
            ''
          )}

          {!isPending && (
            <Box
              m={2}
              width={'50%'}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              {quoteStatus === 'rejected' ? (
                <Alert variant="outlined" severity="error">
                  <AlertTitle>{STATUS[quoteStatus]}</AlertTitle>
                  {quoteData?.rejectionReason}
                  <br />
                  <small>
                    {
                      rejectReasons.find(
                        (x: any) => x?.value === quoteData?.rejectionReason,
                      )?.description
                    }
                  </small>
                </Alert>
              ) : (
                <Status key={quoteStatus} status={STATUS[quoteStatus]} />
              )}
            </Box>
          )}
          <Box>
            <IButtonWithOptions
              options={[
                {
                  title: 'Download Pdf',
                  handleClick: () => window.open(quoteQuery?.data?.pdfUrl),
                  hideThisOption:
                    quoteData?.status === 'new' || !quoteQuery?.data?.pdfUrl,
                },
                {
                  title: 'Hide this Quote',
                  handleClick: handleOpenConfirmationHide,
                  hideThisOption:
                    user?.customerId !== null || quoteQuery?.data?.isPrivate,
                },
                {
                  title: 'Show this Quote',
                  handleClick: handleOpenConfirmationHide,
                  hideThisOption: !quoteQuery?.data?.isPrivate,
                },
                {
                  title: 'Extend expiration date ',
                  handleClick: handleOpenConfirmationExtendDate,
                  hideThisOption: user?.customerId !== null,
                },
                {
                  title: 'Clone this Quote',
                  handleClick: () => router.push(`/quotes/clone/${quoteId}`),
                  hideThisOption: user?.customerId !== null,
                },
              ]}
            />
          </Box>
        </Box>
        <Card sx={{ mb: 0, pb: 0, p: { xs: 2, sm: 2, md: 6 } }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Box
              sx={{
                display: 'inline-flex',
                height: 150,
                width: 150,
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              {hasOfficeLogo ? (
                <img
                  src={office?.logo[0]?.path}
                  alt="logo"
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              ) : (
                <img
                  src="/assets/rulewave/Rulewave-logo-global.JPG"
                  alt="logo"
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              )}
            </Box>
            <Box>
              <Typography variant="h3">QUOTE</Typography>
            </Box>
          </Stack>
          <Box mt={1}>
            <Typography variant="body2">
              <Grid container spacing={2}>
                <Grid item lg={4} sm={4} xs={12}>
                  <Typography variant="body2">
                    <b>From</b>
                    <br />
                    {office?.name || 'Rulewave'}
                    <br />
                    {office?.street || 'Energieweg 2'}
                    <br />
                    {office?.zipCode || '4791 RN'},{' '}
                    {office?.city || 'Klundert (Moerdijk)'}
                    <br />
                    {office?.country === 'NL'
                      ? 'The Netherlands'
                      : office?.country}
                    {/* TODO: Display full country name */}
                  </Typography>
                </Grid>
                <Grid item container lg={4} sm={4} xs={12}>
                  <Typography variant="body2">
                    <b>Customer</b>
                    <br />
                    <Address entity={businessEntity} />
                  </Typography>
                  <Grid
                    container
                    rowSpacing={0.5}
                    columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                    mt={1}
                  >
                    <Grid item xs={3}>
                      <b>Attn</b>
                    </Grid>
                    <Grid item xs={9}>
                      {contact?.name}
                    </Grid>
                    {businessEntity?.phone && (
                      <>
                        <Grid item xs={3}>
                          <b>Phone</b>
                        </Grid>
                        <Grid item xs={9}>
                          {businessEntity?.phone}
                        </Grid>
                      </>
                    )}

                    {businessEntity?.email && (
                      <>
                        <Grid item xs={3}>
                          <b>Email</b>
                        </Grid>
                        <Grid item xs={9}>
                          {businessEntity?.email}
                        </Grid>
                      </>
                    )}
                    {businessEntity?.taxId && (
                      <>
                        <Grid item xs={3}>
                          <b>VAT</b>
                        </Grid>
                        <Grid item xs={9}>
                          {businessEntity?.taxId}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  spacing={0}
                  lg={4}
                  sm={4}
                  md={4}
                  xs={12}
                  justifyContent={'start'}
                  alignItems={'start'}
                >
                  <Grid item container>
                    <Grid item xs={4}>
                      <b>Our ref</b>
                    </Grid>
                    <Grid item xs={8}>
                      {quoteData?.reference}
                    </Grid>
                  </Grid>

                  <Grid item container>
                    <Grid item xs={4}>
                      <b>Your ref</b>
                    </Grid>
                    <Grid item xs={8}>
                      {quoteData?.customerReference || '-'}
                    </Grid>
                  </Grid>

                  <Grid item container>
                    <Grid item xs={4}>
                      <b>Issued</b>
                    </Grid>
                    <Grid item xs={8}>
                      {formatDate(quoteData?.createdAt)}
                    </Grid>
                  </Grid>

                  <Grid item container>
                    <Grid item xs={4}>
                      <b>Valid Until</b>
                    </Grid>
                    <Grid item xs={8} sx={{ display: 'flex', gap: 1 }}>
                      {formatDate(quoteData?.validUntil)}
                      {isPending &&
                        ['warning', 'danger'].includes(dateStatus?.color) && (
                          <Tooltip title={dateStatus.message}>
                            <ErrorOutlineIcon
                              color={
                                isValidColor(dateStatus?.color)
                                  ? dateStatus?.color
                                  : 'inherit'
                              }
                            />
                          </Tooltip>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Typography>
          </Box>

          {quoteData?.description ? (
            <Box sx={{ mt: 4 }}>
              <Divider sx={{ my: 2 }} />
              <Grid container>
                <Grid item xs={2}>
                  <b>Description</b>
                </Grid>
                <Grid item xs={10}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: quoteData?.description || '',
                    }}
                  ></div>
                </Grid>
              </Grid>
            </Box>
          ) : (
            ''
          )}
          {showGreyArea && (
            <Typography variant="body2">
              <Box
                sx={{
                  my: 2,
                  py: 2,
                  mb: 2,
                  mx: -6,
                  px: 6,
                  backgroundColor:
                    theme.palette.mode === 'light' ? '#eee' : '#0c131f',
                }}
              >
                <Grid
                  container
                  spacing={4}
                  m={{ xs: 2, sm: 1, md: 0 }}
                  columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Grid xs={12} md={3}>
                    <Grid
                      container
                      rowSpacing={0.5}
                      columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                      mt={1}
                    >
                      <Grid item xs={5}>
                        <b>MOT</b>
                      </Grid>
                      <Grid item xs={7}>
                        {(quoteData?.mot || '').toUpperCase() || '-'}
                      </Grid>
                      <Grid item xs={5}>
                        <b>IncoTerm</b>{' '}
                      </Grid>
                      <Grid item xs={7}>
                        {incotermValue || '-'}
                      </Grid>
                      <Grid item xs={5}>
                        <b>POL</b>{' '}
                      </Grid>
                      <Grid item xs={7}>
                        {quoteData?.pickUpPort || '-'}
                      </Grid>
                      <Grid item xs={5}>
                        <b>POD</b>{' '}
                      </Grid>
                      <Grid item xs={7}>
                        {quoteData?.deliveryPort || '-'}
                      </Grid>
                    </Grid>
                  </Grid>

                  {!hasEmptyAddressFields({
                    address: quoteData?.pickupAddress,
                  }) && (
                    <Grid xs={12} sm={6} md={3} mt={{ xs: 2, sm: 1, md: 0 }}>
                      <b>Loading Address</b>
                      <Typography variant="body2">
                        <Address entity={quoteData?.pickupAddress} />
                      </Typography>
                    </Grid>
                  )}

                  {!hasEmptyAddressFields({
                    address: quoteData?.deliveryAddress,
                  }) && (
                    <Grid xs={12} sm={6} md={3} mt={{ xs: 2, sm: 1, md: 0 }}>
                      <b>Discharge Address</b>
                      <Typography variant="body2">
                        <Address entity={quoteData?.deliveryAddress} />
                      </Typography>
                    </Grid>
                  )}

                  <Grid xs={12} md={3} mt={{ xs: 2, sm: 1, md: 0 }}>
                    <Grid
                      container
                      rowSpacing={0.5}
                      columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                    >
                      {packagesTotalQuantity !== 0 && (
                        <>
                          <Grid item xs={6}>
                            <b>Total HU</b>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="subtitle2">
                              <NumericFormatText
                                value={packagesTotalQuantity}
                                displayType={'text'}
                              />
                            </Typography>
                          </Grid>
                        </>
                      )}
                      {packagesTotalVolume !== 0 && (
                        <>
                          <Grid item xs={6}>
                            <b>Total CBM</b>
                          </Grid>
                          <Grid item xs={6}>
                            <NumericFormatText
                              value={packagesTotalVolume}
                              displayType={'text'}
                              suffix=" m3"
                            />
                          </Grid>
                        </>
                      )}
                      {packagesTotalWeight !== 0 && (
                        <>
                          <Grid item xs={6}>
                            <b>Total weight</b>
                          </Grid>
                          <Grid item xs={6}>
                            <NumericFormatText
                              value={packagesTotalWeight}
                              displayType={'text'}
                              suffix=" kg"
                            />
                          </Grid>
                        </>
                      )}
                      {chargeableWeight !== 0 && quoteData?.mot === 'air' && (
                        <>
                          <Grid item xs={6}>
                            <b>Chargeable Weight</b>
                          </Grid>
                          <Grid item xs={6}>
                            <NumericFormatText
                              value={chargeableWeight}
                              displayType={'text'}
                              suffix=" kg"
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Typography>
          )}
          <Box sx={{ my: 5, overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '50%' }}>We quote you for</TableCell>
                  <TableCell>Unit Price</TableCell>
                  <TableCell sx={{ width: '75px' }}>Quantity</TableCell>
                  <TableCell align="right">UOM</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {quoteData?.lineItems.map((item: LineItem, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="body2">
                        {item.serviceTypeItem
                          ?.replace(/-/g, ' ')
                          .replace(/\b\w/g, (char) => char.toUpperCase()) || ''}
                        <br />
                        {
                          <small
                            style={{
                              maxWidth: '200px',
                              overflowWrap: 'break-word',
                              wordBreak: 'break-word',
                            }}
                            dangerouslySetInnerHTML={{
                              __html: item?.description || '',
                            }}
                          />
                        }
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <NumericFormatText
                        value={item.unitPrice}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        fixedDecimalScale={true}
                      />
                    </TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell align="right">{item.uom}</TableCell>
                    <TableCell align="right">
                      <NumericFormatText
                        value={calculateRowTotal(item)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        fixedDecimalScale={true}
                      />
                    </TableCell>
                  </TableRow>
                ))}{' '}
                <TableRow>
                  <TableCell
                    colSpan={2}
                    sx={{ borderBottom: '0px' }}
                  ></TableCell>
                  <TableCell colSpan={2}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
                      Subtotal
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle2">
                      <NumericFormatText
                        value={quoteData?.subTotal}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        fixedDecimalScale={true}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    sx={{ borderBottom: '0px' }}
                  ></TableCell>

                  <TableCell colSpan={2}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
                      VAT ({vatPercentage?.label})
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle2">
                      <NumericFormatText
                        value={calculateTotalSubtotalDifference(
                          quoteData?.total || 0,
                          quoteData?.subTotal || 0,
                        )}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        fixedDecimalScale={true}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    sx={{ borderBottom: '0px' }}
                  ></TableCell>
                  <TableCell colSpan={2}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
                      Total
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle2">
                      <NumericFormatText
                        value={quoteData?.total}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        fixedDecimalScale={true}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          {termsList.length > 0 ? (
            <Box sx={{ mt: 2 }}>
              <Typography gutterBottom variant="h6">
                Terms and Conditions
              </Typography>
              <ul color="text.secondary">
                {termsList.map((item: any) => (
                  <li key={item.id}>
                    <Typography color="text.secondary" variant="body2">
                      {item.name}
                    </Typography>
                  </li>
                )) || []}
              </ul>
            </Box>
          ) : (
            ''
          )}

          {packages?.length > 0 ? (
            <>
              <Typography fontWeight={'bold'} sx={{ mt: 3, mb: 1 }}>
                Commodity Details
              </Typography>
              <Box sx={{ overflowX: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Commodity Description</TableCell>
                      <TableCell>Package Type</TableCell>
                      <TableCell>Length</TableCell>
                      <TableCell>Width</TableCell>
                      <TableCell>Height</TableCell>
                      <TableCell>Weight</TableCell>
                      <TableCell align="right">Volume</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {packages?.map((pkg, index) => (
                      <TableRow key={index}>
                        <TableCell>{pkg?.qty}</TableCell>
                        <TableCell
                          style={{
                            maxWidth: '200px',
                            overflowWrap: 'break-word',
                            wordBreak: 'break-word',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: pkg?.description || '',
                          }}
                        ></TableCell>
                        <TableCell>{pkg?.packageType}</TableCell>
                        <TableCell>
                          {pkg?.dimensions.length} {pkg?.dimensions.unit}
                        </TableCell>
                        <TableCell>
                          {pkg?.dimensions.width} {pkg?.dimensions.unit}
                        </TableCell>
                        <TableCell>
                          {pkg?.dimensions.height} {pkg?.dimensions.unit}
                        </TableCell>
                        <TableCell>
                          <NumericFormatText
                            value={pkg?.weight.value}
                            displayType={'text'}
                            suffix={' ' + pkg?.weight.unit}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumericFormatText
                            value={pkg?.dimensions.volume?.value}
                            displayType={'text'}
                            suffix=" m3"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      <NumericFormatText
                        value={calculatePackagesTotal(packages)}
                        displayType={'text'}
                      />
                    </TableCell>
                    <TableCell colSpan={5}></TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      <NumericFormatText
                        value={calculatePackagesWeight(packages)}
                        displayType={'text'}
                        suffix=" kg"
                      />
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} align="right">
                      <NumericFormatText
                        value={calculatePackagesVolume(packages)}
                        displayType={'text'}
                        suffix=" m3"
                      />
                    </TableCell>
                  </TableRow>
                </Table>
              </Box>
            </>
          ) : (
            ''
          )}

          {quoteData?.remarks ? (
            <Box sx={{ my: 4 }}>
              <Grid container>
                <Grid item xs={2}>
                  <b>Remarks</b>
                </Grid>
                <Grid item xs={10}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: quoteData?.remarks || '',
                    }}
                  ></div>
                </Grid>
              </Grid>
            </Box>
          ) : (
            ''
          )}
          <Box
            sx={{
              mt: 3,
              mb: -6,
              py: 2,
              mx: -6,
              px: 6,
              pb: { xs: 7, sm: 1 },
              minHeight: '130px',
              backgroundColor:
                theme.palette.mode === 'light' ? '#89CFF0' : '#191d36',
            }}
          >
            <Grid container spacing={2} p={1}>
              <Grid item sm={8}>
                {footerNotes.map((item: string, index: number) => (
                  <Typography
                    fontSize={'small'}
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: item || '',
                    }}
                  ></Typography>
                ))}
              </Grid>
              <Grid item sm={4} sx={{ textAlign: 'center' }}>
                <Typography variant="body2">
                  <strong>Global Logistics Service Provider</strong> <br />
                  <small>
                    The Netherlands | United States | Singapore | Malaysia |
                    Dubai | Turkey | Azerbaijan | Kazakhstan
                  </small>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Card>
        <ConfirmRejectDialog
          open={openConfirmation}
          message={`Are you sure you want to reject this quote?`}
          onCancel={() => handleCloseConfirmation()}
          buttonId="rejectQuote"
          formik={formik}
        />

        <ConfirmationDialog
          open={openConfirmationHide}
          message={`Are you sure you want to ${
            quoteQuery?.data?.isPrivate ? 'show' : 'hide'
          } this quote?`}
          onCancel={() => handleCloseConfirmationHide()}
          onConfirm={handleHide}
          buttonId="hideQuoteDetails"
          title="Hide quote"
        />
        <ConfirmExtendDateDialog
          open={openConfirmationExtendDate}
          message={`Choose a new date for this quote`}
          onCancel={() => handleCloseConfirmationExtendDate()}
          buttonId="extendValidDate"
          formik={formik}
          handleConfirm={handleConfirmExtendDate}
        />
        <ConfirmationDialog
          open={openConfirmationAccept}
          message={`Are you sure you want to accept this quote?`}
          onCancel={() => handleCloseConfirmationAccept()}
          onConfirm={() => {
            return accpptQuote.mutate()
          }}
          buttonId="acceptQuote"
          title="Accept quote"
        />
      </Container>
      {!noLongerValid && (isPending || isFinal) && (
        <StickyFooter
          rightComponent={
            <>
              {isPending && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleOpenConfirmationAccept}
                  startIcon={<ThumbUpIcon />}
                >
                  Accept
                </Button>
              )}
              {isFinal && (
                <Button
                  color="primary"
                  isLoading={sendQuoteMutation.isLoading}
                  variant="contained"
                  onClick={() => {
                    quoteId && sendQuoteMutation.mutate({ entityId: quoteId })
                  }}
                >
                  Send
                </Button>
              )}
            </>
          }
          leftComponent={
            <>
              {isPending && (
                <Button
                  color="error"
                  variant="outlined"
                  onClick={handleOpenConfirmation}
                  startIcon={<ThumbDownIcon />}
                >
                  Reject
                </Button>
              )}
            </>
          }
        />
      )}
    </Box>
  )
}

export default PreviewQuote
