/* eslint-disable @typescript-eslint/no-explicit-any */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import SearchIcon from '@mui/icons-material/Search'
import Checkbox from '@mui/material/Checkbox'
import {
  Box,
  Grid,
  InputAdornment,
  Table as MUiTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import TableFooter from '@mui/material/TableFooter'
import { makeStyles } from '@mui/styles'
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { debounce } from 'lodash'
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import {
  removeSimpleFilter,
  updateGeneralSearch,
  updateSimpleFilter,
  updateSorting,
} from 'src/slices/FiltersSlice'
import { NoRecordsFound } from '../no-records'
import FilterButton from './FilterButton'
import { FitlerColumn, InlineFitlerColumn } from './types'
import { useInfiniteData } from './useInfiniteData'
import { convertFiltersToString, generateFilterString } from './utils'
// import { SearchIcon } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'
import { useDispatch } from 'react-redux'
import { SettingsContext } from 'src/common/contexts/settings-context'
import {
  removeSelectRow,
  addSelectRow,
} from 'src/slices/InfiniteGridSelectSlice'

const SortingIconsWrapper = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}))

const SortBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  marginLeft: '8px',
  maxWidth: '20px',
})

const StyledArrowIcon = styled('div')({
  cursor: 'pointer',
})

const StyledArrowUpIcon = styled(KeyboardArrowUpIcon)({
  marginBottom: '-5px',
  cursor: 'pointer',
})

export type SortDirection = 'asc' | 'desc'

export type ColumnSort = {
  id: string
  desc: boolean
}

interface InlineFilter {
  name: string
  filterName?: string
  component: (props: {
    variant: string
    className: string
    value?: any
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  }) => ReactNode
}

interface DefaultHeaderComponentProps {
  header: any
  valueOfColumnFilter: string
  classes: any
  queryKey: string
  dispatch: (action: any) => void // You might want to define the type of action
  handleFilterChange: (filter: any) => void
}

function DefaultHeaderComponent({
  header,
  valueOfColumnFilter,
  classes,
  queryKey,
  dispatch,
  handleFilterChange,
}: DefaultHeaderComponentProps) {
  const minWidth = (header: any) => {
    const length = header.column.columnDef.header.length
    if (length > 10) {
      return length * 15
    }
    if (length > 15) {
      return length * 8
    }
    return 180
  }

  return (
    <TextField
      fullWidth
      id="standard-search"
      label={flexRender(header.column.columnDef.header, header.getContext())}
      variant="outlined"
      size="small"
      className={classes.root}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        handleFilterChange({
          id: header.column.columnDef.queryParamName
            ? header.column.columnDef.queryParamName
            : header.id,
          value: e?.target?.value,
        })
      }}
      style={{
        minWidth: `${minWidth(header)}px`,
      }}
      InputProps={{
        endAdornment:
          valueOfColumnFilter !== '' ? (
            <InputAdornment position="end">
              <CloseIcon
                sx={{
                  cursor: 'pointer',
                }}
                fontSize="small"
                onClick={() =>
                  dispatch(
                    removeSimpleFilter({
                      filterKey: queryKey,
                      filter: {
                        column: header.id,
                      },
                    }),
                  )
                }
              />
            </InputAdornment>
          ) : null,
      }}
      value={valueOfColumnFilter}
    />
  )
}

interface InlineFilterComponentProps {
  header: any
  customInlineFilters: InlineFilter[] | null
  classes: any
  handleFilterChange: (filter: any) => void
  valueOfColumnFilter: any
}
function InlineFilterComponent({
  header,
  customInlineFilters,
  classes,
  handleFilterChange,
  valueOfColumnFilter,
}: InlineFilterComponentProps) {
  const inlineFilter = customInlineFilters?.find(
    (inlineFilter) =>
      inlineFilter.name?.toLowerCase() === header.id?.toLowerCase(),
  )
  if (!inlineFilter) return <></>

  return (
    <>
      {inlineFilter.component({
        variant: 'outlined',
        className: classes.root,
        value: valueOfColumnFilter,
        onChange: (e) => {
          handleFilterChange({
            id:
              inlineFilter?.filterName || header.column.columnDef.queryParamName
                ? inlineFilter?.filterName ||
                  header.column.columnDef.queryParamName
                : header.id,
            value: e?.target?.value,
          })
        },
      })}
    </>
  )
}

const HeaderActionsComponent = ({ header, classes }: any) => {
  return (
    <div
      style={{
        pointerEvents: 'none',
      }}
    >
      <TextField
        fullWidth
        id="standard-search"
        label={flexRender(header.column.columnDef.header, header.getContext())}
        className={classes.root}
        variant="outlined"
        size="small"
      />
    </div>
  )
}

const joinFilters = (advancedFilterString: any, simpleFilterString: any) => {
  let result = ''

  if (advancedFilterString && simpleFilterString) {
    result = advancedFilterString + '&' + simpleFilterString
  } else if (advancedFilterString) {
    result = advancedFilterString
  } else if (simpleFilterString) {
    result = simpleFilterString
  }
  return result
}

export type SortingStateSlice = ColumnSort[]

interface InfiniteGridProps<T extends object> {
  columns: ColumnDef<T>[]
  filtersColumns?: FitlerColumn[] | null
  customInlineFilters?: InlineFitlerColumn[] | null
  fetchData: (
    start: number,
    fetchSize: number,
    sorting?: SortingState,
    filters?: string,
    generalSearch?: string,
  ) => Promise<{ data: T[]; totalRowCount: number }>
  fetchSize?: number
  queryKey: string
  onRowClick?: (row: any) => void
  fullWidthHorizontalScroll?: boolean
  useSearch?: boolean
  showSerachBox?: boolean
  style?: any
  rowSelect?: boolean
  otherFilters?: string
}

export function InfiniteGrid<T extends object>({
  columns,
  filtersColumns = null,
  customInlineFilters = null,
  fetchData,
  fetchSize = 20,
  queryKey,
  onRowClick,
  fullWidthHorizontalScroll,
  useSearch = true,
  showSerachBox = true,
  style = {},
  rowSelect,
  otherFilters,
}: InfiniteGridProps<T>) {
  const settings = useContext(SettingsContext)

  const useStyles = makeStyles((theme: any) => {
    return {
      root: {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'transparent',
          },
          '&:hover fieldset': {
            borderColor: theme.palette.grey[400],
          },
          '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main,
          },
          '& .MuiSvgIcon-root': {
            cursor: 'default',
          },
        },
      },
      activeSortIcon: {
        color: theme.palette.primary.main,
      },
      inactiveSortIcon: {
        color: theme.palette.grey[400],
      },
      clickableRow: {
        cursor: 'pointer',
        '&:hover':
          settings.paletteMode === 'light'
            ? {
                backgroundColor: theme.palette.grey[100],
              }
            : { backgroundColor: '#1c2639' },
      },
      stickyCell: {
        position: 'sticky',
        right: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: 10,
        boxShadow: '-2px 0px 2px -2px rgba(0,0,0,0.1)',
        width: '30px',
      },
    }
  })
  const dispatch = useDispatch()

  const selectedRows = useSelector(
    (state: any) => state.selectRow.selectedRows[queryKey] || [],
  )
  const isSelected = (rowId: string) => selectedRows.includes(rowId)

  const handleCheckboxChange = (rowId: string) => {
    if (isSelected(rowId)) {
      dispatch(removeSelectRow({ id: rowId, tableKey: queryKey }))
    } else {
      dispatch(addSelectRow({ id: rowId, tableKey: queryKey }))
    }
  }
  // const [sorting, setSorting] = React.useState<SortingState>([])
  const sorting = useSelector(
    (state: any) => state.filters.sorting[queryKey] || [],
  )

  const advancedFilters = useSelector(
    (state: any) => state.filters.advancedFilters[queryKey] || [],
  )

  const simpleFilters = useSelector(
    (state: any) => state.filters.simpleFilters[queryKey] || [],
  )

  const generalSearch = useSelector(
    (state: any) => state.filters.generalSearch[queryKey],
  )

  const [debouncedAdvancedFilters, setDebouncedFilters] =
    useState(advancedFilters)

  const [debouncedSimpleFilters, setDebouncedSimpleFilters] =
    useState(simpleFilters)

  const [debouncedGeneralSearch, setDebouncedGeneralSearch] =
    useState(generalSearch)

  const classes = useStyles()

  useEffect(() => {
    const debouncedUpdate = debounce((filters) => {
      setDebouncedSimpleFilters(filters)
    }, 500) // 500ms delay

    debouncedUpdate(simpleFilters)

    // Clean up function
    return () => {
      debouncedUpdate.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(simpleFilters)])

  useEffect(() => {
    const debouncedUpdate = debounce((filters) => {
      setDebouncedFilters(filters)
    }, 500) // 500ms delay

    debouncedUpdate(advancedFilters)

    // Clean up function
    return () => {
      debouncedUpdate.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(advancedFilters)])

  useEffect(() => {
    const debouncedUpdateSearch = debounce((searchTerm) => {
      setDebouncedGeneralSearch(searchTerm)
    }, 500) // 500ms delay

    debouncedUpdateSearch(generalSearch)

    // Clean up function
    return () => {
      debouncedUpdateSearch.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalSearch])

  const advancedFilterString = useMemo(
    () => generateFilterString(debouncedAdvancedFilters),
    [debouncedAdvancedFilters],
  )

  const simpleFilterString = useMemo(
    () => convertFiltersToString(debouncedSimpleFilters, useSearch),
    [debouncedSimpleFilters, useSearch],
  )

  const { data, fetchNextPage, isFetching, isLoading } = useInfiniteData({
    key: queryKey,
    fetchData,
    fetchSize,
    sorting,
    filters: joinFilters(advancedFilterString, simpleFilterString),
    generalSearch: debouncedGeneralSearch,
    useSearch: useSearch,
    otherFilters,
  })

  const flatData = useMemo(
    () => data?.pages?.flatMap((page) => page.data) ?? [],
    [data],
  )

  const totalFilteredItems = data?.pages?.[0]?.totalFilteredItems ?? 0
  const totalRowCount = data?.pages?.[0]?.totalRowCount ?? 0
  const totalFetched = flatData.length
  const totalPages = data?.pages?.[0]?.totalPages ?? 0
  const currentPage = data?.pages?.[0]?.currentPage ?? 0

  const tableContainerRef = useRef<HTMLDivElement | null>(null)

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const refData = containerRefElement
        const { scrollHeight, scrollTop, clientHeight } = refData

        if (
          scrollHeight - scrollTop - clientHeight < 200 &&
          !isFetching &&
          totalFetched < totalFilteredItems &&
          currentPage &&
          currentPage < totalPages
        ) {
          fetchNextPage()
        }
      }
    },
    [
      isFetching,
      totalFetched,
      totalFilteredItems,
      currentPage,
      totalPages,
      fetchNextPage,
    ],
  )

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])

  const table = useReactTable({
    data: flatData,
    columns,
    state: {
      sorting,
    },
    onSortingChange: (newSortingState: any) => {
      dispatch(
        updateSorting({
          filterKey: queryKey,
          sorting: newSortingState() as SortingStateSlice,
        }),
      )
    },

    getCoreRowModel: getCoreRowModel(),
  })
  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 61, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  })

  const virtualRows = rowVirtualizer.getVirtualItems()
  const totalSize = rowVirtualizer.getTotalSize()

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0

  const handleFilterChange = (filter: any) => {
    if (filter.value === '') {
      dispatch(
        removeSimpleFilter({
          filterKey: queryKey,
          filter: { column: filter.id },
        }),
      )
    } else {
      dispatch(
        updateSimpleFilter({
          filterKey: queryKey,
          filter: { column: filter.id, value: filter.value },
        }),
      )
    }
  }
  const getSortingColor = (id: string, orientation: 'desc' | 'asc') => {
    if (sorting?.length > 0) {
      const sortedItem = sorting.find((x: any) => x.id === id)
      if (sortedItem) {
        if (orientation === 'desc') {
          return sortedItem?.desc ? 'primary' : 'disabled'
        }
        if (orientation === 'asc') {
          return !sortedItem?.desc ? 'primary' : 'disabled'
        }
      }
    }
    return 'disabled'
  }
  return (
    <div id={queryKey}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
        }}
      >
        {useSearch && showSerachBox && (
          <TextField
            id={'search-' + queryKey}
            label="Search"
            variant="outlined"
            value={generalSearch}
            onChange={(e) => {
              dispatch(
                updateGeneralSearch({
                  term: e.target.value,
                  filterKey: queryKey,
                }),
              )
            }}
            sx={{
              width: '380px',
            }}
            InputProps={{
              // endAdornment: generalSearch ? (
              // eslint-disable-next-line no-constant-condition
              endAdornment: false ? (
                <InputAdornment
                  position="end"
                  onClick={() => {
                    setDebouncedGeneralSearch('')
                    dispatch(
                      updateGeneralSearch({
                        term: '',
                        filterKey: queryKey,
                      }),
                    )
                  }}
                >
                  <ClearIcon
                    sx={{
                      cursor: 'pointer',
                    }}
                  />
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <SearchIcon
                    sx={{
                      cursor: 'pointer',
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        )}
        <Box>
          {filtersColumns && (
            <FilterButton columns={filtersColumns} filterKey={queryKey} />
          )}
        </Box>
      </Box>
      {isLoading && <LinearProgress />}
      <TableContainer
        component={Paper}
        ref={tableContainerRef}
        onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
        id={queryKey}
        data-testid={queryKey}
        style={{
          height: '800px',
          overflow: 'auto', //  scrollable table container
          position: 'relative', //needed for sticky header
          width: '100%',
          ...style,
        }}
      >
        <div style={{ overflowX: 'auto', width: '100%' }}>
          <MUiTable
            sx={{
              width: fullWidthHorizontalScroll
                ? `${settings.containerWidth}px`
                : '100%',
              display: 'grid',
            }}
          >
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow
                  key={headerGroup.id}
                  style={{ display: 'flex', width: '100%' }}
                >
                  {headerGroup.headers.map((header, index, headersArray) => {
                    const isLastHeader =
                      index === headersArray.length - 1 &&
                      header.id === 'actions'

                    if (!header) return null

                    const valueOfColumnFilter =
                      simpleFilters?.find(
                        (filter: any) =>
                          filter.column === header.id ||
                          filter.column ===
                            (header.column.columnDef as any).queryParamName,
                      )?.value || ''

                    return rowSelect && index === 0 ? (
                      <TableCell
                        key="check"
                        colSpan={1}
                        style={{
                          display: 'flex',
                          flexGrow: 0,
                          width: '50',
                          paddingLeft: 0,
                          cursor: 'pointer',
                        }}
                      >
                        <Box sx={{ width: '50px' }}></Box>
                      </TableCell>
                    ) : (
                      <TableCell
                        key={header.id}
                        colSpan={header.colSpan}
                        className={isLastHeader ? classes.stickyCell : ''}
                        style={{
                          display: 'flex',
                          flexGrow: header.getSize() > 30 ? 0 : 1,
                          width:
                            header.getSize() > 30 ? header.getSize() : 'auto',
                          paddingLeft: 0,
                          cursor: header.column.getCanSort()
                            ? 'pointer'
                            : 'default',
                        }}
                      >
                        {!header.isPlaceholder && (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ width: '100%' }}
                          >
                            <Grid
                              item
                              xs={header.id === 'actions' ? 12 : 10}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {header.id === 'actions' ? (
                                <HeaderActionsComponent
                                  header={header}
                                  classes={classes}
                                />
                              ) : (
                                <>
                                  {customInlineFilters?.find(
                                    (inlineFilter) =>
                                      inlineFilter.name?.toLowerCase() ===
                                      header.id?.toLowerCase(),
                                  ) ? (
                                    <InlineFilterComponent
                                      header={header}
                                      customInlineFilters={customInlineFilters}
                                      classes={classes}
                                      handleFilterChange={handleFilterChange}
                                      valueOfColumnFilter={valueOfColumnFilter}
                                    />
                                  ) : (
                                    <DefaultHeaderComponent
                                      header={header}
                                      valueOfColumnFilter={valueOfColumnFilter}
                                      classes={classes}
                                      queryKey={queryKey}
                                      dispatch={dispatch}
                                      handleFilterChange={handleFilterChange}
                                    />
                                  )}
                                </>
                              )}
                            </Grid>
                            {header.column.getCanSort() && (
                              <SortingIconsWrapper
                                item
                                xs={header.id === 'actions' ? 0 : 2}
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                <SortBox>
                                  <>
                                    <StyledArrowUpIcon
                                      as={KeyboardArrowUpIcon}
                                      onClick={() =>
                                        header.column.toggleSorting()
                                      }
                                      color={getSortingColor(header.id, 'asc')}
                                    />
                                    <StyledArrowIcon
                                      as={KeyboardArrowDownIcon}
                                      onClick={() =>
                                        header.column.toggleSorting()
                                      }
                                      color={getSortingColor(header.id, 'desc')}
                                    />
                                  </>
                                </SortBox>
                              </SortingIconsWrapper>
                            )}
                          </Grid>
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableHead>

            <TableBody
              style={{
                display: 'grid',
                height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                position: 'relative', //needed for absolute positioning of rows
              }}
            >
              {paddingTop > 0 && (
                <TableRow>
                  <TableCell style={{ height: `${paddingTop}px` }} />
                </TableRow>
              )}
              {virtualRows.length === 0 && (
                <TableRow id="no-rows">
                  <Box p={8}>
                    <NoRecordsFound />
                  </Box>
                </TableRow>
              )}

              {virtualRows.map((virtualRow) => {
                const row = rows[virtualRow.index] as any

                return (
                  <TableRow
                    key={row.id}
                    className={onRowClick ? classes.clickableRow : ''}
                    data-testid={`row-${queryKey}-${virtualRow.index}`}
                    data-index={virtualRow.index}
                    ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                      width: '100%',
                    }}
                  >
                    {row
                      .getVisibleCells()
                      .map((cell: any, cellIndex: number, cellArray: any) => {
                        const isLastCell =
                          cellIndex === cellArray.length - 1 &&
                          cell.id.includes('actions')

                        return (
                          <>
                            {cellIndex === 0 && rowSelect ? (
                              <TableCell
                                key="check"
                                colSpan={1}
                                style={{
                                  display: 'flex',
                                  flexGrow: 0,
                                  width: '50',
                                  paddingLeft: 0,
                                  cursor: 'pointer',
                                }}
                              >
                                <Checkbox
                                  id={cell.getContext().cell.getValue()}
                                  checked={isSelected(
                                    cell.getContext().cell.getValue(),
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      cell.getContext().cell.getValue(),
                                    )
                                  }
                                />
                              </TableCell>
                            ) : (
                              <TableCell
                                onClick={() =>
                                  onRowClick &&
                                  !isLastCell &&
                                  onRowClick(row.original)
                                }
                                key={cell.id}
                                sx={{
                                  padding: 1,
                                  paddingLeft: cellIndex === 0 ? 1 : 0,
                                }}
                                style={{
                                  display: 'flex',
                                  width:
                                    cell.column.getSize() > 30
                                      ? cell.column.getSize() - 1
                                      : 'auto',
                                  flexGrow: cell.column.getSize() > 30 ? 0 : 1,
                                  alignItems: 'center',
                                }}
                                className={isLastCell ? classes.stickyCell : ''}
                                data-testid={`cell-${virtualRow.index}-${cellIndex}`}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </TableCell>
                            )}
                          </>
                        )
                      })}
                  </TableRow>
                )
              })}

              {paddingBottom > 0 && (
                <TableRow>
                  <TableCell style={{ height: `${paddingBottom}px` }} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter
              sx={{
                position: 'sticky',
                bottom: 0,
                background: classes.stickyCell,
              }}
            >
              {totalRowCount > 1 && (
                <TableRow>
                  <TableCell style={{ height: `${paddingBottom}px` }}>
                    <Typography fontSize="body">
                      {`${totalFilteredItems} rows`}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ height: `${paddingBottom}px` }}>
                    <Typography fontSize="body" color="secondary">
                      {isFetching && <div>Fetching More...</div>}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </MUiTable>
        </div>
      </TableContainer>
      {(isLoading || isFetching) && <LinearProgress />}
    </div>
  )
}
