import { Stack } from '@mui/material'
import VendorsAutocomplete from 'src/common/components/autocomplete/vendor'
import RenderField from 'src/common/components/form/RenderField'
import { useGetCurrencySymbol } from 'src/common/hooks/use-currency-sign'

interface FormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any
}

const PurchaseOrderForm = ({ formik }: FormProps) => {
  const officeData = JSON.parse(localStorage.getItem('activeOffice') || '{}')

  const currencySymbol = useGetCurrencySymbol(officeData?.currency)

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <RenderField
          formik={formik}
          field={{ label: 'Base Price *', name: 'basePrice', type: 'number' }}
          prefix={currencySymbol}
        />

        <VendorsAutocomplete formik={formik} name="vendorId" />

        <RenderField
          formik={formik}
          field={{
            label: 'Purchase Description',
            name: 'description',
            type: 'textarea',
          }}
        />
      </Stack>
    </form>
  )
}

export default PurchaseOrderForm
